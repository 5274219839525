import React from 'react';
import Restru from './components/basics/Restru'
const App = () => {
    return (
           <Restru/> 
       
    );
};

export default App
